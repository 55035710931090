import * as React from "react";
import { Table } from "react-bootstrap";
import { TableHeader, EventTitle, EventDate, TaskStatusTd } from "./styled";

interface TasksTableProps {
  title: string;
  content: Task[];
}

interface Task {
  title: string;
  status?: string;
  date?: string;
}

const TasksTable: React.FC<TasksTableProps> = (props: TasksTableProps) => {
  const parseDate = (date: string | undefined): string | undefined => {
    if (date) {
      const x = new Date(date);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(x);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(x);
      return `${da} ${mo}`;
    }
  };

  return (
    <Table>
      <TableHeader>
        <tr>
          <th colSpan={3}>{props.title}</th>
        </tr>
      </TableHeader>
      <tbody>
        {props.content.map((task, i) => {
          return (
            <tr key={`task-${i}`} id={task.title}>
              <TaskStatusTd>
                <EventDate> {task.status} </EventDate>
              </TaskStatusTd>
              <td>
                <EventTitle>{task.title}</EventTitle>
              </td>
              <td>
                <EventTitle>Due {parseDate(task.date)}</EventTitle>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TasksTable;
