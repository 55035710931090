import styled from "styled-components/macro";
export const NeedTitle = styled.div`
  font-weight: 600;
  color: #212529;
`;

export const NeedDescription = styled.div`
  font-size: 85%;
`;

export const NeedDatesContainer = styled.div`
  font-size: 85%;
  color: #999;
  font-style: italic;
  margin-top: 0.25rem;
  & p {
    margin: 0;
  }
`;
