import { Goal } from "./Goal";
import { RelatedPerson } from "./RelatedPerson";
import {
  CarePlan,
  Organization,
  ServiceRequest,
} from "../containers/Home/dataConverter";
import { Practitioner } from "./Practitioner";
import { Composition } from "./Composition";
import { Encounter } from "./Encounter";
import { Task } from "./Task";
import { Condition } from "./Condition";
import { Patient } from "./Patient";

export interface CareTeam {
  resource: {
    id: string;
    participant: (
      | ExternalParticipant
      | {
          role: { text: string }[];
          member: { reference: string; type: string };
        }
    )[];
    resourceType: string;
  };
  fullUrl: string;
}

export interface ExternalParticipant {
  role: { text: string }[];
  member: { reference: string; type: string };
  onBehalfOf: { display: string };
}

export function isExternalParticipant(object:ExternalParticipant | {
  role: { text: string }[];
  member: { reference: string; type: string };
} ): object is ExternalParticipant {
  return (object as ExternalParticipant).onBehalfOf !== undefined;

}
export function isCareTeam(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
): object is CareTeam {
  return (object as CareTeam).resource.resourceType === "CareTeam";
}
