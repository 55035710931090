import styled from "styled-components/macro";
export const TableHeader = styled.span`
  color: #999;
  font-style: italic;
  font-size: 13px;
  margin-bottom: 8px;
`;

export const FilterContainer = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
`;

export const FiltersContainer = styled.div`
  margin-bottom: 16px;
  margin-top: 8px;

  & a:not(:first-of-type) {
    border-top-width: 0;
  }

  & a:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  & a:last-of-type {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem;
  }
`;

export const FilterItem = styled.a`
  position: relative;
  display: block;
  padding: 12px 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 100%;
  color: #495057;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  :hover {
    color: #495057;
    background-color: whitesmoke;
    text-decoration: none;
  }
`;

export const FilterItemCounter = styled.span`
  padding-right: 6px;
  padding-left: 6px;
  padding-bottom: 3px;
  padding-top: 3px;
  border-radius: 160px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.2;
`;

export const FilterItemCounterNone = styled.span`
  padding-right: 6px;
  padding-left: 6px;
  padding-bottom: 3px;
  padding-top: 3px;
  border-radius: 160px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.2;
  background-color: #6c757d;
  margin-top: 3px;

  font-weight: 300;
  :after {
    content: "None";
  }
`;
