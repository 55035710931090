import * as React from "react";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { StatusSpan } from "./styled";
interface StatusProps {
  status: string;
}

const Status: React.FC<StatusProps> = (props: StatusProps) => {
  const getColor = () => {
    switch (props.status) {
      case "identified":
        return "#dc3545";
      case "in_progress":
        return "#ffc107";
      case "fulfilled":
        return "#28a745";
    }
  };
  const mapToText = () => {
    switch (props.status) {
      case "identified":
        return "Identified";
      case "in_progress":
        return "In Progress";
      case "fulfilled":
        return "Need Fulfilled";
    }
  }

  return (
    <div>
      <FiberManualRecordIcon
        style={{ fontSize: "medium", color: getColor() }}
      />
      <StatusSpan>{mapToText()}</StatusSpan>
    </div>
  );
};

export default Status;
