import styled from "styled-components/macro";
export const AppContainer = styled.div`
  padding: 24px;
  font-size: 13px;
  line-height: 1.3;
  font-family: "Open Sans", sans-serif;
`;
export const ActivateCareLogoContainer = styled.img`
  padding-right: 1rem;
  height: 2rem;
`;

export const PatientData = styled.div`
  padding: 0 15px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 10pt;
`;

export const PatientName = styled.div`
  font-weight: bolder;
`;

export const PatientDetails = styled.div`
  color: #6c757d;
`;

export const ActivateCareLogotype = styled.div`
  font-size: 18pt;
  display: inline;
  margin-right: 8px;
  color: #1b3348;
  & span:first-of-type {
    font-weight: 700;
  }
`;

export const Title = styled.div`
  font-size: 18pt;
  display: inline;
  color: #6c757d;
`;

export const BrandContainer = styled.div`
  padding: 0 15px;
  display: flex;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  margin: 24px 0;
  display: flex;
  justify-content: space-between;
`;

export const ContentContainer = styled.div`
  padding: 15px;
`;
