import { Composition } from "./Composition";
import {
  CarePlan,
  Organization,
  ServiceRequest,
} from "../containers/Home/dataConverter";
import { Task } from "./Task";
import { Goal } from "./Goal";
import { CareTeam } from "./CareTeam";
import { Practitioner } from "./Practitioner";
import { RelatedPerson } from "./RelatedPerson";
import { Encounter } from "./Encounter";
import { Patient } from "./Patient";

export interface Condition {
  resource: {
    note: { text: string }[];
    code: { text: string };
    subject: { reference: string; type: string };
    meta: { lastUpdated: string };
    recordedDate: string;
    id: string;
    category: { text: string }[];
    resourceType: string;
    text: {div: string}
  };
  fullUrl: string;
}
export function isCondition(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
): object is Condition {
  return (object as Condition).resource.resourceType === "Condition";
}
