import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { ProtectedRoute } from "./ProtectedRoute";
import Launcher from "./Launcher";
import Auth from "./Auth";
import Home from "./containers/Home";

function App() {
  const { error, isLoading } = useAuth0();

  if (error && !isLoading) {
    return (
      <>
        <h1 style={{ color: "red" }}>API auth error</h1>
        <p>{error.message}</p>
      </>
    );
  }

  return (
    <Router>
      <ProtectedRoute path="/app/:active_tab" component={Home} />
      <Route path="/auth" component={Auth} />
      <Route path="/launch" component={Launcher} />
      <Route path="/" component={Launcher} exact />
    </Router>
  );
}

export default App;
