import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { useFhirClient } from "./FhirClientProvider";

function Auth() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [fhirclient, smartLoading] = useFhirClient();

  useEffect(() => {
    if (isAuthenticated || smartLoading || !fhirclient || isLoading) return;
    loginWithRedirect();
  }, [loginWithRedirect, isAuthenticated, smartLoading, fhirclient, isLoading]);

  if (isAuthenticated) return <Redirect to="/app/home" />;

  return <h1>Running API auth flow...</h1>;
}

export default Auth;
