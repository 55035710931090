import styled from "styled-components/macro";

export const TableContainer = styled.div``;

export const TableHeader = styled.thead`
  background-color: whitesmoke;
  & th {
    font-weight: 400 !important;
  }
`;

export const CenteredTd = styled.td`
  vertical-align: middle !important;
  width: 55%;
`;
