import styled from "styled-components/macro";
export const ContactHeaderContainer = styled.div`{    
  margin-top: 5px;
  padding-right: 1rem;
  color: #6c757d!important;
  & svg {
    font-size: 13px !important;
    margin-right: 4px;
  }
`;

export const ContactCardContainer = styled.div`{    
  margin-top: 5px;
`;
