import styled from "styled-components/macro";
export const TableContainer = styled.div`
  width: 100%;
}
`;

export const TableHeader = styled.thead`
  background-color: whitesmoke;
  & th {
    font-weight: 400 !important;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const NeedContainer = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
`;
