import { Composition } from "./Composition";
import {
  CarePlan,
  Organization,
  ServiceRequest,
} from "../containers/Home/dataConverter";
import { Encounter } from "./Encounter";
import { Goal } from "./Goal";
import { Practitioner } from "./Practitioner";
import { RelatedPerson } from "./RelatedPerson";
import { CareTeam } from "./CareTeam";
import { Condition } from "./Condition";
import { Patient } from "./Patient";

export interface Task {
  resource: {
    description: string;
    id: string;
    lastModified: string;
    intent: string;
    resourceType: string;
    status: string;
  };
  fullUrl: string;
}

export function isTask(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
): object is Task {
  return (object as Task).resource.resourceType === "Task";
}
