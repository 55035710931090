import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Fade, Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ActivateCareLogo from "../../assets/activate_care_circle.png";
import {
  ActivateCareLogoContainer,
  ActivateCareLogotype,
  AppContainer,
  BrandContainer,
  ContentContainer,
  HeaderContainer,
  PatientData,
  PatientDetails,
  PatientName,
  Title,
} from "./styled";
import CareTeam from "../CareTeam";
import CarePlan from "../CarePlan";
import NeedsReferrals from "../NeedsReferrals";
import Summary from "../Summary";
import DataConverter from "./dataConverter";

import { useFhirClient } from "../../FhirClientProvider";
import { fhirclient as fhirtypes } from "fhirclient/lib/types";
import ky from 'ky';

function Home() {
  const [key, setKey] = useState<string | null>("home");
  const [error, setError] = useState<string | null>(null);

  const [patient, setPatient] = useState<fhirtypes.FHIR.Patient | null>();
  const [fhirclient] = useFhirClient();

  const { getAccessTokenSilently } = useAuth0();

  const [testApiUser, setTestApiUser] = useState(undefined as any);
  // const { loading, error, data: testApiUser = [] } = useApi(
  //   // `api/fhir/r4/v1/Composition/edc9713b-52d1-465b-bbcc-e5c8a99a8b57`
  //   `api/fhir/r4/v1/Composition/7fa2b19c-7ab8-4421-a494-fc81aeaa1334/$document`
  // );
  useEffect(() => {
    if (!fhirclient) return;

    // TODO: Could be used later for org detection?
    // console.log("ISS (to detect orgs): ", fhirclient.getIdToken()?.iss);

    fhirclient.patient.read().then((_patient: fhirtypes.FHIR.Patient) => {
      setPatient(_patient);
    });
  }, [fhirclient]);

  const fetchData = async (ids: string[]) => {
    const api = ky.extend({
      hooks: {
        beforeRequest: [
          request => {
            request.headers.set('Authorization', `Bearer ${accessToken}`);
          }
        ]
      }
    });

    const accessToken = await getAccessTokenSilently({})
    const base_url = `${window.location.protocol}//${process.env.REACT_APP_AC_API_URL}/`;
    // const res = await fetch(
    //   base_url +
    //   `api/fhir/r4/v1/CarePlan?subject.identifier=` +
    //   ids.join(","),
    //   {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     }
    //   });
    // const jsonData = await res.json()
    // console.log("!!! care plan res", res);

    const jsonData = await api.get(
      base_url +
      `api/fhir/r4/v1/CarePlan?subject.identifier=` +
      ids.join(","),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .json();

    // @ts-ignore
    const bundleLen = jsonData.entry.length;
    let carePlan;

    for (let i = 0; i < bundleLen; i++) {
      // @ts-ignore
      const entry = jsonData.entry[i];
      if (entry.resource.resourceType === "CarePlan") {
        carePlan = entry.resource;
        break;
      }
    }

    const compositionId = carePlan.identifier[0].value;
    const base_composition_url = `${window.location.protocol}//${process.env.REACT_APP_AC_API_URL}/`;

    // const composition = await fetch(
    //   base_composition_url + `api/fhir/r4/v1/Composition/${compositionId}/$document`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   }
    // )

    const composition = await api.get(
      base_composition_url + `api/fhir/r4/v1/Composition/${compositionId}/$document`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
    )
    const compositionJsonData = await composition.json()
    setTestApiUser(compositionJsonData);
  }

  useEffect(() => {
    if (!patient) return;
    const ids = patient.identifier.map((id: any) => {
      return id.value;
    });
    // ids.push("204178")
      fetchData(ids).catch(e => {
        setError("Error during data fetching, in case of problems reach out to: help@activatecare.com")
      })
  }, [patient]);

  const getName = () => {
    let name = patient?.name.find((n: any) => n.use === "official");
    if (!name) {
      name = patient?.name[0];
    }
    if (!name) return "Placeholder";
    return name.text ? name.text : name.given[0] + " " + name.family;
  };

  const parseDate = (date: string | undefined): string | undefined => {
    if (date) {
      const x = new Date(date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(x);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(x);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(x);
      return `${da} ${mo} ${ye}`;
    }
  };

  try {
    if(error) {
      return <div>{error}</div>
    }
    return (
      <AppContainer>
        <HeaderContainer>
          <BrandContainer>
            <ActivateCareLogoContainer src={ActivateCareLogo} alt="Logo" />
            <ActivateCareLogotype>
              <span>Activate</span>
              <span>Care</span>
            </ActivateCareLogotype>
            <Title>Social Dashboard</Title>
          </BrandContainer>
          <PatientData>
            <PatientName>{getName()}</PatientName>
            <PatientDetails>
              {patient?.gender} · DOB {parseDate(patient?.birthDate)}
            </PatientDetails>
          </PatientData>
        </HeaderContainer>
        <ContentContainer>
          {testApiUser && (
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              transition={Fade}
              activeKey={key}
              onSelect={(k) => setKey(k)}
              style={{ marginBottom: "24px" }}
            >
              <Tab eventKey="home" title="Summary">
                <Summary
                  {...DataConverter.convertToSummaryProps(testApiUser)}
                  changeTab={(k) => setKey(k)}
                />
              </Tab>
              <Tab eventKey="needs" title="Needs & Referrals">
                <NeedsReferrals
                  {...DataConverter.convertToNeedsReferralsProps(testApiUser)}
                />
              </Tab>
              <Tab eventKey="careplan" title="Care Plan">
                <CarePlan
                  {...DataConverter.convertToCarePlanProps(testApiUser)}
                />
              </Tab>
              <Tab eventKey="careteam" title="Care Team">
                <CareTeam
                  {...DataConverter.convertToCareTeamProps(testApiUser)}
                />
              </Tab>
            </Tabs>
          )}
        </ContentContainer>
      </AppContainer>
    );
  } catch (e) {
    setError("Error during data processing")
    return (
      <div>{error}</div>
    )
  }
}

export default Home;
