import styled from "styled-components/macro";
export const TableHeader = styled.thead`
  & th {
    font-weight: 400 !important;
    border: none !important;
  }
`;

export const EventDateTd = styled.td`
  vertical-align: middle !important;
  padding-left: 0;
  width: 20%;
`;

export const EventTitle = styled.div``;

export const EventDescription = styled.div`
  color: #6c757d !important;
  font-size: 85%;
`;

export const EventDate = styled.div``;

export const EventHour = styled.div`
  color: ##212529 !important;
  font-size: 85%;
`;
