import { Composition } from "./Composition";
import {
  CarePlan,
  Organization,
  ServiceRequest,
} from "../containers/Home/dataConverter";
import { CareTeam } from "./CareTeam";
import { Practitioner } from "./Practitioner";
import { RelatedPerson } from "./RelatedPerson";
import { Goal } from "./Goal";
import { Task } from "./Task";
import { Condition } from "./Condition";
import { Patient } from "./Patient";

export interface Encounter {
  resource: {
    period: { start: string };
    id: string;
    text: { div: string; status: string };
    type: { text: string }[];
    class: { system: string; code: string };
    resourceType: string;
    status: string;
  };
  fullUrl: string;
}

export function isEncounter(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
): object is Encounter {
  return (object as Encounter).resource.resourceType === "Encounter";
}
