import * as React from "react";

import {
  FilterContainer,
  FilterItem,
  FilterItemCounter,
  FilterItemCounterNone,
  FiltersContainer,
  TableHeader,
} from "./styled";
import { Category, getIcon, Need } from "../index";

interface NeedsFilterProps {
  name: string;
  categories: {[key in keyof typeof Category]: Need[]};
}

const NeedsFilter: React.FC<NeedsFilterProps> = (props: NeedsFilterProps) => {
  const getColor = (status: string | undefined) => {
    switch (status) {
      case "identified":
        return "#dc3545";
      case "in_progress":
        return "#d98519";
      case "fulfilled":
        return "#28a745";
      default:
        return "#dc3545";
    }
  };

  const needs = [
    { name: "Education" },
    { name: "Food" },
    { name: "Housing" },
    { name: "Care" },
    { name: "Goods" },
    { name: "Health" },
    { name: "Legal" },
    { name: "Money" },
    { name: "Transit" },
    { name: "Work" },
  ];

  needs.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  return (
    <FilterContainer>
      <TableHeader>
        {props.name} has service needs in the following domains:
      </TableHeader>
      <FiltersContainer>
        {
          Object.keys(props.categories).map((item, i) => {
            return (
              <FilterItem key={`active-need-${i}`} href={`#${item}`}>
                <div>
                  {getIcon(item)}
                  <span style={{ marginLeft: "5px" }}>{item}</span>
                </div>
                {/* @ts-ignore */}
                <FilterItemCounter style={{ backgroundColor: getColor(props.categories[item][0].status) }}>
                  {/* @ts-ignore */}
                  {props.categories[item].length}
                </FilterItemCounter>
              </FilterItem>
            );
          })
        }
      </FiltersContainer>
      <TableHeader>
        No service needs have been identified yet for {props.name} in these
        domains:
      </TableHeader>
      <FiltersContainer>
        {
          needs.filter((el) =>
            //@ts-ignore
            !props.categories[el.name])
          .map((need, i) => {
            return (
              <FilterItem
                key={`need-${i}`}
                style={{ backgroundColor: "whitesmoke" }}
              >
                <div>
                  {getIcon(need.name)}
                  <span style={{ marginLeft: "5px" }}>{need.name}</span>
                </div>
                <FilterItemCounterNone />
              </FilterItem>
            );
          })}
      </FiltersContainer>
    </FilterContainer>
  );
};

export default NeedsFilter;
