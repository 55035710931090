import * as React from "react";
import { Table } from "react-bootstrap";
import { CenteredTd, TableContainer, TableHeader } from "./styled";
import PersonCard from "./PersonCard";
import ContactCard from "./ContactCard";

export interface CareTeamProps {
  careTeam: CareMember[];
}

export interface CareMember {
  name: string;
  role: string;
  address?: Address[];
  contacts?: Contact[];
  organizationName?: string;
}

export interface Contact {
  system: string;
  value: string;
  use?: string;
}

export interface Address {
  line?: string[];
  postalCode?: string;
  state?: string;
}

const CareTeam: React.FC<CareTeamProps> = (props: CareTeamProps) => {
  const uniqueOrganizationNames = props.careTeam
    .map((el) => el.organizationName)
    .filter((x, i, a) => a.indexOf(x) === i);
  return (
    <TableContainer>
      {uniqueOrganizationNames.map((organizationName, oi) => (
        <Table key={`org-${oi}`} id={"CareTeam"}>
          <TableHeader>
            <tr>
              <th colSpan={3}>{organizationName}</th>
            </tr>
          </TableHeader>
          <tbody>
            {props.careTeam
              .filter((member) => member.organizationName === organizationName)
              .map((member, i) => {
                return (
                  <tr key={`member-${i}`} id={member.name}>
                    <CenteredTd>
                      <PersonCard person={member} />
                    </CenteredTd>
                    <CenteredTd>
                      {member.contacts && (
                        <ContactCard
                          contacts={member.contacts}
                          address={member.address}
                          kind={
                            member.contacts.find(
                              (contact) => contact.use !== undefined
                            )?.use || "Work"
                          }
                        />
                      )}
                    </CenteredTd>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ))}
    </TableContainer>
  );
};

export default CareTeam;
