import * as React from "react";
import { GoalDetails, SectionContainer, SectionTitleContainer } from "./styled";

interface SectionProps {
  title: string;
  content: string;
  status?: string;
  date?: string;
}

const Section: React.FC<SectionProps> = (props: SectionProps) => {
  return (
    <SectionContainer>
      <SectionTitleContainer>{props.title}</SectionTitleContainer>
      { (props.date !== "" || props.status !== undefined ) && (
        <GoalDetails>
          Goal is
          {props.status !== undefined && <span>{" " + props.status + " "}</span>}
          {props.date !== "" &&
          <span>
            to be achieved by
            <span>{" " + props.date}</span>
          </span>
          }
        </GoalDetails>
      )}
      <div dangerouslySetInnerHTML={{ __html: props.content }} />
    </SectionContainer>
  );
};

export default Section;
