import * as React from "react";
import { Table } from "react-bootstrap";
import {
  TableHeader,
  EventTitle,
  EventDescription,
  EventDate,
  EventHour,
  EventDateTd,
} from "./styled";

interface EventsTableProps {
  title: string;
  content: Event[];
}

interface Event {
  title: string;
  description: string;
  date?: string;
}

const EventsTable: React.FC<EventsTableProps> = (props: EventsTableProps) => {
  const parseDate = (date: string | undefined): string | undefined => {
    if (date) {
      const x = new Date(date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(x);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(x);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(x);
      return `${da} ${mo} ${ye}`;
    }
  };

  const parseHour = (date: string | undefined): string | undefined => {
    if (date) {
      const x = new Date(date);
      let hour = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
      }).format(x);
      return hour;
    }
  };
  return (
    <Table>
      <TableHeader>
        <tr>
          <th colSpan={3}>{props.title}</th>
        </tr>
      </TableHeader>
      <tbody>
        {props.content.map((event, i) => {
          return (
            <tr key={`event-${i}`} id={event.title}>
              <EventDateTd>
                <EventDate> {parseDate(event.date)} </EventDate>
                <EventHour> {parseHour(event.date)}</EventHour>
              </EventDateTd>
              <td>
                <EventTitle>{event.title}</EventTitle>
                <EventDescription
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default EventsTable;
