import { Composition } from "./Composition";
import { CarePlan, Organization, ServiceRequest } from "../containers/Home/dataConverter";
import { Encounter } from "./Encounter";
import { Task } from "./Task";
import { Goal } from "./Goal";
import { CareTeam } from "./CareTeam";
import { Practitioner } from "./Practitioner";
import { Condition } from "./Condition";
import { RelatedPerson } from "./RelatedPerson";

export interface Patient {
  resource: {
    gender: string;
    name: { given: string[]; text: string; family: string }[];
    id: string;
    birthDate: string;
    resourceType: string;
    address: { line: string[]; postalCode: string; state: string }[];
    telecom: (
      | { system: string; use: string,  value: string }
      | { system: string; value: string }
      )[];
  };
  fullUrl: string;
}

export function isPatient(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
): object is Patient {
  return (object as Patient).resource.resourceType === "Patient";
}