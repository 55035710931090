import styled from "styled-components/macro";
// import styled, { createGlobalStyle } from 'styled-components/macro'

export const SectionTitleContainer = styled.h1`
  font-size: 18pt;
  font-weight: 400;
`;

export const SectionContainer = styled.div`
  margin-bottom: 10px;
  color: #212529;
`;

export const GoalDetails = styled.div`
  border-top: 1px dashed #ccc;
  color: #6c757d !important;
  padding-top: 8px !important;
  margin-bottom: 8px !important;
  & span {
    font-weight: 700;
  }
`;
