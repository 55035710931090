import React, { useState, useLayoutEffect } from "react";
import { oauth2 as SMART, client as FhirClient } from "fhirclient";

// Type imports
import Client from "fhirclient/lib/Client";

export const FhirClientStateContext = React.createContext<
  [Client | null | undefined, Boolean]
>([undefined, true]);

interface Props {
  children: React.ReactNode;
}

function FhirClientProvider(props: Props) {
  const { children } = props;
  const [client, setClient] = useState<Client | null>();
  const [error, setError] = useState<Error | null>();
  const [isLoading, setLoading] = useState<Boolean>(true);

  // Manual fix for lib not supporting `fhirUser` field (used by Epic)
  function mockedGetFhirUser(this: any) {
    const idToken = this.getIdToken();
    if (idToken) {
      if (idToken.hasOwnProperty("profile")) {
        return idToken.profile;
      } else if (idToken.hasOwnProperty("fhirUser")) {
        // Epic returns absolute URL, extract only the FHIR Resource part
        const fhirUser = idToken.fhirUser.split("/").slice(-2).join("/");
        return fhirUser;
      }
    }
    return null;
  }

  useLayoutEffect(() => {
    if (client) return;

    // If we are on /auth page - assume it's initial SMART flow
    // EHR redirects to /auth, Auth0 redirects to /app
    if (window.location.pathname.startsWith("/auth")) {
      setLoading(true);
      SMART.ready()
        .then(
          (_client: Client) => {
            _client.getFhirUser = mockedGetFhirUser;
            setClient(_client);
          },
          (_error) => setError(_error)
        )
        .finally(() => setLoading(false));
      return;
    }

    // Otherwise load the state from the Session (after redirect from 2nd OAuth)
    const fhirSession = sessionStorage.getItem("SMART_KEY")?.slice(1, -1);
    if (fhirSession) {
      const fhirState = sessionStorage.getItem(fhirSession);
      if (!fhirState) {
        console.error("SMART session state not found.");
        return;
      }
      const _client = FhirClient(JSON.parse(fhirState));
      _client.getFhirUser = mockedGetFhirUser;
      _client.refresh();
      setClient(_client);
      return;
    }
    setLoading(false);
  }, [client]);

  if (error) {
    console.error(error);
  }

  return (
    <FhirClientStateContext.Provider value={[client, isLoading]}>
      {children}
    </FhirClientStateContext.Provider>
  );
}

function useFhirClient(): any {
  const context = React.useContext(FhirClientStateContext);
  return context;
}

export { FhirClientProvider, useFhirClient };
