import * as React from "react";
import { ContactCardContainer, ContactHeaderContainer } from "./styled";
import { Phone, Mail, LocationOn } from "@material-ui/icons";
import { Address, Contact } from "../index";

interface ContactCardProps {
  contacts?: Contact[];
  address?: Address[];
  kind: string;
}

const ContactCard: React.FC<ContactCardProps> = (props: ContactCardProps) => {
  return (
    <ContactCardContainer>
      {props.contacts &&
        props.contacts.map((contact, i) => {
          return (
            <div key={`contact-${i}`}>
              <ContactHeaderContainer>
                {contact.system === "phone" && <Phone />}
                {contact.system === "email" && <Mail />}
                <span>{props.kind}</span>
              </ContactHeaderContainer>
              <div>{contact.value}</div>
            </div>
          );
        })}
      {props.address &&
        props.address.map((address, i) => {
          return (
            <div key={`address-${i}`}>
              <ContactHeaderContainer>
                <LocationOn />
                <span>{props.kind}</span>
              </ContactHeaderContainer>

              {address.line &&
                address.line.map((lineAddress, j) => (
                  <span key={`address-${i}-${j}`}>{lineAddress}</span>
                ))}
              <div>
                <span>{address.state + " " + address.postalCode}</span>
              </div>
            </div>
          );
        })}
    </ContactCardContainer>
  );
};

export default ContactCard;
