import * as React from "react";
import { SectionContainer, TableContainer } from "./styled";
import EventsTable from "./EventsTable";
import Section from "./Section";
import TasksTable from "./TasksTable";

export interface CarePlanProps {
  goals: Section[];
}

export interface Section {
  name: string;
  description: string;
  events?: Event[];
  tasks?: Task[];
  status?: string;
  date?: string;
}

interface Event {
  title: string;
  description: string;
  date?: string;
}

interface Task {
  title: string;
  status?: string;
  date?: string;
}
const CarePlan: React.FC<CarePlanProps> = (props: CarePlanProps) => {
  return (
    <TableContainer>
      {props.goals.map((goal, i) => {
        return (
          <SectionContainer key={`goal-${i}`}>
            <Section
              content={goal.description}
              title={goal.name}
              date={goal.date}
              status={goal.status}
            />
            {goal.events && goal.events?.length > 0 && (
              <EventsTable title="Related Events" content={goal.events} />
            )}
            {goal.tasks && goal.tasks?.length > 0 && (
              <TasksTable title="Related Tasks" content={goal.tasks} />
            )}
          </SectionContainer>
        );
      })}
    </TableContainer>
  );
};

export default CarePlan;
