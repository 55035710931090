import {
  CarePlan,
  Organization,
  ServiceRequest,
} from "../containers/Home/dataConverter";
import { RelatedPerson } from "./RelatedPerson";
import { CareTeam } from "./CareTeam";
import { Practitioner } from "./Practitioner";
import { Goal } from "./Goal";
import { Encounter } from "./Encounter";
import { Task } from "./Task";
import { Condition } from "./Condition";
import { Patient } from "./Patient";

export function isComposition(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
    | Goal
): object is Composition {
  return (object as Composition).resource.resourceType === "Composition";
}

export interface Composition {
  resource: {
    date: string;
    author: { display: string; type: string }[];
    subject: { reference: string; type: string };
    section: (EventSection | GoalSection)[];
    type: {
      coding: { system: string; code: string; display: string }[];
      text: string;
    };
    title: string;
    resourceType: string;
    status: string;
  };
  fullUrl: string;
}

export interface GoalSection {
  focus: { reference: string; type: string };
  text: { div: string; status: string };
  title: string;
}

export interface EventSection {
  entry: (
    | { reference: string; type: string }
    | { reference: string; type: string }
  )[];
  text: { div: string; status: string };
  title: string;
}

export function isGoalSection(
  object: GoalSection | EventSection
): object is GoalSection {
  return (object as GoalSection).focus !== undefined;
}

export function isEventSection(
  object: GoalSection | EventSection
): object is EventSection {
  return (object as EventSection).entry !== undefined;
}
