import * as React from "react";
import {
  PageContainer,
  PersonAvatarContainer,
  PatientInfo,
  Description,
  ElementLink,
  ElementInfoParagraph,
  NeedDate,
} from "./styled";
import AvatarPlaceholder from "../../assets/placeholder.gif";
import { getIcon } from "../NeedsReferrals";

export interface SummaryProps {
  name: string;
  age: string;
  gender: string;
  goals: Goal[];
  needs: Need[];
  careTeamMembersNumber: number;
  changeTab: (param: string) => void;
}

interface Goal {
  name: string;
}

interface Need {
  category: string;
  title: string;
  date: string;
  referrals: Referral[];
}

interface Referral {
  institution?: string;
}

const Summary: React.FC<SummaryProps> = (props: SummaryProps) => {
  const getNumberOfReferrals = () => {
    let numberOfReferrals = 0;
    props.needs.forEach((need) => {
      numberOfReferrals += need.referrals.length;
    });
    return numberOfReferrals;
  };

  const parseDate = (date: string | undefined): string | undefined => {
    if (date) {
      const x = new Date(date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(x);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(x);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(x);
      return `${da} ${mo} ${ye}`;
    }
  };
  return (
    <PageContainer>
      <div>
        <PersonAvatarContainer src={AvatarPlaceholder} alt="Avatar" />
      </div>
      <Description>
        <PatientInfo>
          {props.name +
            " is a " +
            props.age +
            "-year-old" +
          (props.gender === "unknown" ? "" : (" " + props.gender)) +
            "..."}
        </PatientInfo>
        <ElementInfoParagraph>
          <p style={{ marginBottom: "16px" }}>
            {"...with "}
            <ElementLink onClick={() => props.changeTab("careplan")}>
              {props.goals.length + " goals in their care plan:"}
            </ElementLink>
          </p>
          <ul>
            {props.goals.map((goal, i) => {
              return (
                <li key={`goal-${i}`} style={{ fontWeight: 400 }}>
                  {goal.name}
                </li>
              );
            })}
          </ul>
        </ElementInfoParagraph>
        <ElementInfoParagraph>
          <p style={{ marginBottom: "16px" }}>
            {"...with "}
            <ElementLink onClick={() => props.changeTab("needs")}>
              {props.needs.length +
                " unmet needs and " +
                getNumberOfReferrals() +
                " active" + (getNumberOfReferrals() > 1 ? "referrals" : "referral") + ":"}
            </ElementLink>
          </p>
          <ul>
            {props.needs.map((need, i) => {
              return (
                <li key={`need-${i}`} style={{ fontWeight: 400 }}>
                  {getIcon(need.category, "21px")}
                  {need.title}
                  <NeedDate>{" (as of " + parseDate(need.date) + ")"}</NeedDate>
                  {need.referrals.length > 0 && (
                    <ul>
                      {need.referrals.map((referral, j) => {
                        return (
                          <li
                            key={`referral-${j}`}
                            style={{ fontWeight: 200, fontStyle: "italic" }}
                          >
                            {"They have an open referral to " +
                              referral.institution}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </ElementInfoParagraph>
        {props.careTeamMembersNumber > 0 && (
          <ElementInfoParagraph>
            {"...and "}
            <ElementLink onClick={() => props.changeTab("careplan")}>
              {props.careTeamMembersNumber + " people in their care team."}
            </ElementLink>
          </ElementInfoParagraph>
        )}
      </Description>
    </PageContainer>
  );
};

export default Summary;
