import {
  CarePlan,
  Organization,
  ServiceRequest,
} from "../containers/Home/dataConverter";
import { Goal } from "./Goal";
import { CareTeam } from "./CareTeam";
import { RelatedPerson } from "./RelatedPerson";
import { Composition } from "./Composition";
import { Encounter } from "./Encounter";
import { Task } from "./Task";
import { Condition } from "./Condition";
import { Patient } from "./Patient";

export interface Practitioner {
  resource: {
    address: { line: string[]; postalCode: string; state: string }[];
    name: { given: string[]; text: string; family: string }[];
    telecom: (
      | { system: string; value: string }
      | { system: string; use: string; value: string }
    )[];
    id: string;
    resourceType: string;
  };
  fullUrl: string;
}

export function isPractitioner(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
): object is Practitioner {
  return (object as Practitioner).resource.resourceType === "Practitioner";
}
