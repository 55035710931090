import { CarePlanProps, Section } from "../CarePlan";
import { CareMember, CareTeamProps } from "../CareTeam";
import { Goal, isGoal } from "../../api/Goal";
import { isRelatedPerson, RelatedPerson } from "../../api/RelatedPerson";
import {
  CareTeam,
  isCareTeam,
  isExternalParticipant,
} from "../../api/CareTeam";
import { isPractitioner, Practitioner } from "../../api/Practitioner";
import {
  Composition,
  isComposition,
  isEventSection,
  isGoalSection,
} from "../../api/Composition";
import { Encounter, isEncounter } from "../../api/Encounter";
import { isTask, Task } from "../../api/Task";
import { NeedsReferralsProps } from "../NeedsReferrals";
import { Condition, isCondition } from "../../api/Condition";
import { isPatient, Patient } from "../../api/Patient";
import { SummaryProps } from "../Summary";

enum DataType {
  Practitioner = "Practitioner",
  RelatedPerson = "RelatedPerson",
}
export interface ApiData {
  identifier: { value: string };
  entry: (
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
  )[];
  type: string;
  resourceType: string;
  timestamp: string;
}

export interface CarePlan {
  resource: {
    addresses: { reference: string; type: string }[];
    goal: { reference: string; type: string }[];
    activity: {
      reference: { reference: string; type: string };
      progress: { text: string }[];
    }[];
    subject: { reference: string; type: string };
    id: string;
    intent: string;
    careTeam: { reference: string; type: string }[];
    resourceType: string;
    status: string;
  };
  fullUrl: string;
}
export function isCarePlan(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
): object is CarePlan {
  return (object as CarePlan).resource.resourceType === "CarePlan";
}

export interface ServiceRequest {
  resource: {
    authoredOn: string;
    performer: { reference: string; type: string }[];
    subject: { reference: string; type: string };
    reasonReference: { reference: string; type: string }[];
    id: string;
    intent: string;
    resourceType: string;
    status: string;
  };
  fullUrl: string;
}

export function isServiceRequest(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
): object is ServiceRequest {
  return (object as ServiceRequest).resource.resourceType === "ServiceRequest";
}

export interface Organization {
  resource: {
    partOf?: { reference: string; type: string };
    name: string;
    id: string;
    resourceType: string;
  };
  fullUrl: string;
}
export function isOrganization(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
): object is Organization {
  return (object as Organization).resource.resourceType === "Organization";
}

export default class DataConverter {
  static convertToCarePlanProps(data: ApiData): CarePlanProps {
    const sections: Section[][] = data.entry
      .filter<Composition>(isComposition)
      .map((composition) => {
        return composition.resource.section.map((section) => {
          let goal;
          if (isGoalSection(section)) {
            goal = data.entry
              .filter<Goal>(isGoal)
              .find((item) => item.fullUrl === section?.focus?.reference);
          }
          let tasks;
          if (isEventSection(section)) {
            tasks = section.entry.map((task) => {
              const event = data.entry
                .filter<Encounter>(isEncounter)
                .find((item) => item.fullUrl === task.reference);
              const tasks = data.entry
                .filter<Task>(isTask)
                .find((item) => item.fullUrl === task.reference);
              if (event) {
                return {
                  type: "event",
                  title: event.resource.type[0].text,
                  description: event.resource.text.div,
                  date: event.resource.period.start,
                };
              } else if (tasks) {
                return {
                  type: "task",
                  title: tasks.resource.description,
                  description: tasks.resource.description,
                  status: tasks.resource.status,
                  date: tasks.resource.lastModified,
                };
              }
              return null;
            });
          }
          const result = tasks?.filter(notEmpty);
          return {
            name: section.title || "",
            description: section.text.div,
            status: goal?.resource.lifecycleStatus,
            date: (goal?.resource.target && goal?.resource.target[0].dueDate) || "",
            events: result?.filter((el) => el?.type === "event") || [],
            tasks: result?.filter((el) => el?.type === "task") || [],
          };
        });
      });
    return {
      goals: sections[0],
    };
  }

  static convertToCareTeamProps(data: ApiData): CareTeamProps {
    const careMembers = data.entry
      .filter<CareTeam>(isCareTeam)
      .map((careTeam) => {
        const participants: (CareMember | null)[] = careTeam.resource.participant.map(
          (participant) => {
            let memberPersonalData;
            switch (participant.member.type) {
              case DataType.Practitioner:
                memberPersonalData = data.entry
                  .filter<Practitioner>(isPractitioner)
                  .find((el) => el.fullUrl === participant.member.reference);
                break;
              case DataType.RelatedPerson:
                memberPersonalData = data.entry
                  .filter<Patient | RelatedPerson>(isPatient || isRelatedPerson)
                  .find((el) => el.fullUrl === participant.member.reference);
                break;
            }
            if (memberPersonalData) {
              return {
                name: memberPersonalData.resource.name[0].text,
                address: memberPersonalData.resource.address,
                contacts: memberPersonalData.resource.telecom,
                role: participant.role[0].text,
                organizationName: isExternalParticipant(participant)
                  ? participant?.onBehalfOf?.display
                  : "Family",
              };
            }
            return null;
          }
        );
        return participants;
      });
    const result = careMembers[0].filter(notEmpty);
    return {
      careTeam: result,
    };
  }

  static convertToNeedsReferralsProps(data: ApiData): NeedsReferralsProps {
    const needs = data.entry.filter<Condition>(isCondition).map((condition) => {
      const serviceRequest = data.entry.filter<ServiceRequest>(isServiceRequest).find( serviceRequest => serviceRequest.resource.reasonReference[0].reference === condition.fullUrl)
      let organization: Organization | undefined;
      let performer : Organization | undefined;
      if(serviceRequest) {
        organization = data.entry.filter<Organization>(isOrganization).find( org => org.fullUrl === serviceRequest.resource.performer[0].reference)
        performer = data.entry.filter<Organization>(isOrganization).find( org => org.fullUrl === organization?.resource.partOf?.reference )
      }
      const carePlan = data.entry.filter<CarePlan>(isCarePlan);
      return {
        category: condition.resource.category[0].text,
        title: condition.resource.code.text,
        description: condition.resource.text.div,
        date: condition.resource.recordedDate,
        updatedDate: condition.resource.meta.lastUpdated,
        createdDate: condition.resource.recordedDate,
        status: condition.resource.note[0].text,
        referrals: carePlan[0].resource?.activity?.filter(el => el.reference.reference === serviceRequest?.fullUrl).map(activityItem => {
          return {
            status: activityItem.progress[0].text,
            programName: organization?.resource.name,
            date: serviceRequest?.resource.authoredOn,
            institution: performer?.resource.name
          }
        }) || []
      };
    });
    const patient = data.entry.filter<Patient>(isPatient)[0];
    return {
      needs: needs,
      patientName: patient.resource.name[0].text,
    };
  }

  static convertToSummaryProps(
    data: ApiData
  ): Pick<SummaryProps, Exclude<keyof SummaryProps, "changeTab">> {
    const patient = data.entry.filter<Patient>(isPatient)[0];

    const goals = data.entry
      .filter<Composition>(isComposition)
      .map((composition) => {
        return composition.resource.section.map((el) => {
          return {
            name: el.title,
          };
        });
      });
    const needs = data.entry.filter<Condition>(isCondition).map((condition) => {
      const serviceRequest = data.entry.filter<ServiceRequest>(isServiceRequest).find( serviceRequest => serviceRequest.resource.reasonReference[0].reference === condition.fullUrl)
      let organization: Organization | undefined;
      let performer : Organization | undefined;
      if(serviceRequest) {
        organization = data.entry.filter<Organization>(isOrganization).find( org => org.fullUrl === serviceRequest.resource.performer[0].reference)
        performer = data.entry.filter<Organization>(isOrganization).find( org => org.fullUrl === organization?.resource.partOf?.reference )
      }
      const carePlan = data.entry.filter<CarePlan>(isCarePlan);
      return {
        category: condition.resource.category[0].text,
        title: condition.resource.code.text,
        date: condition.resource.recordedDate,
        status: condition.resource.note[0].text,
        referrals: carePlan[0].resource?.activity?.filter(el => el.reference.reference === serviceRequest?.fullUrl).map(activityItem => {
          return {
            institution: performer?.resource.name
          }
        }) || []
      };
    });

    const careMembers = data.entry
      .filter<CareTeam>(isCareTeam)
      .map((careTeam) => {
        return careTeam.resource.participant.length;
      });

    return {
      name: patient.resource.name[0].text,
      age: calculateAge(patient.resource.birthDate),
      gender: patient.resource.gender,
      goals: goals[0],
      needs: needs,
      careTeamMembersNumber: careMembers[0],
    };
  }
}

function calculateAge(date: string): string {
  const one_year = 1000 * 60 * 60 * 24 * 365;
  const theDate = new Date(date);
  const today = new Date();
  const result = Math.round((theDate.getTime() - today.getTime()) / one_year);
  return Math.abs(result).toFixed(0);
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
