import styled from "styled-components/macro";
export const ReferralContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-weight: 300;
`;

export const ReferralHeaderContainer = styled.div`
  border-bottom: 1px solid #ddd;
  color: #888;
  margin-bottom: 0.2rem;
  font-size: 8pt;
  padding: 0.25rem 0.5rem;
`;

export const ReferralBodyContainer = styled.div`
  border-bottom: 1px solid #ddd;
  font-size: 8pt;
  padding: 0.25rem 0.5rem;
`;

export const InstitutionNameContainer = styled.div`
  font-weight: 400;
`;

export const ReferralStatusContainer = styled.div`
    text-transform: uppercase;
    font-size: 8pt;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
`;
