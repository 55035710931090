import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { FhirClientProvider } from "./FhirClientProvider";
import { Auth0Provider } from "@auth0/auth0-react";

const auth0redirectUrl = `${window.location.protocol}//${window.location.host}${
  process.env.REACT_APP_AC_OAUTH_CALLBACK || ""
}`;

ReactDOM.render(
  <React.StrictMode>
    <FhirClientProvider>
      <Auth0Provider
        domain={process.env.REACT_APP_AC_OAUTH_DOMAIN || ""}
        clientId={process.env.REACT_APP_AC_OAUTH_CLIENT || ""}
        redirectUri={auth0redirectUrl}
        connection={process.env.REACT_APP_AUTH0_CONNECTION}
      >
        <App />
      </Auth0Provider>
    </FhirClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
