import * as React from "react";
import {
  ReferralBodyContainer,
  ReferralContainer,
  ReferralHeaderContainer,
  InstitutionNameContainer,
  ReferralStatusContainer,
} from "./styled";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckIcon from "@material-ui/icons/Check";
interface ReferralCardProps {
  referrals?: Referral[];
}

export interface Referral {
  programName?: string;
  institution?: string;
  status?: string;
  date?: string;
}

const ReferralCard: React.FC<ReferralCardProps> = (
  props: ReferralCardProps
) => {
  const parseDate = (date: string | undefined): string | undefined => {
    if (date) {
      const x = new Date(date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(x);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(x);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(x);
      return `${da} ${mo} ${ye}`;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "requested":
        return "#ffc107"
      case "accepted":
        return "#007bff"
      case "declined":
        return "#dc3545"
      case "awaiting_more_info":
        return "#17a2b8"
      case "services_delivered":
        return "#28a745"
      case "wait_listed" :
        return "#6c757d"
      case "services_not_delivered":
        return "#343a40"
      case "withdrawn":
        return "#6c757d"
    }
  }
  return (
    <div>
      {(!props.referrals || props.referrals.length === 0) &&
        "No referrals made yet."}
      {props.referrals &&
        props.referrals.length > 0 &&
        props.referrals.map((ref, i) => {
          return (
            <ReferralContainer key={`ref-${i}`}>
              <ReferralHeaderContainer>
                REFERRAL · {parseDate(ref.date)}
              </ReferralHeaderContainer>
              <ReferralBodyContainer>
                <InstitutionNameContainer>
                  <ArrowForwardIcon style={{ fontSize: "10pt" }} />
                  {ref.programName}
                </InstitutionNameContainer>
                {ref.institution}
              </ReferralBodyContainer>
              {ref.status && <ReferralStatusContainer style={{ color: getStatusColor(ref.status) }}>
                {ref.status === "services_delivered" && (
                  <CheckIcon style={{ fontSize: "10pt" }} />
                )}
                {ref.status.split('_').join(' ')}
              </ReferralStatusContainer> }
            </ReferralContainer>
          );
        })}
    </div>
  );
};

export default ReferralCard;
