import * as React from "react";

import {
  PersonAvatarContainer,
  PersonCardContainer,
  PersonData,
  PersonNameContainer,
  PersonRoleContainer,
} from "./styled";
import AvatarPlaceholder from "../../../assets/placeholder.gif";

interface PersonCardProps {
  person: {
    name: string;
    role: string;
  };
}

const PersonCard: React.FC<PersonCardProps> = (props: PersonCardProps) => {
  return (
    <PersonCardContainer>
      <PersonAvatarContainer src={AvatarPlaceholder} alt="Avatar" />
      <PersonData>
        <PersonNameContainer>{props.person.name}</PersonNameContainer>
        <PersonRoleContainer>{props.person.role}</PersonRoleContainer>
      </PersonData>
    </PersonCardContainer>
  );
};

export default PersonCard;
