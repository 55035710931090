import * as React from "react";
import { Table } from "react-bootstrap";
import {
  NeedContainer,
  PageContainer,
  TableContainer,
  TableHeader,
} from "./styled";
import Status from "./Status ";
import NeedInfo from "./NeedInfo";
import ReferralCard, { Referral } from "./ReferralCard";
import SchoolIcon from "@material-ui/icons/School";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import HomeIcon from "@material-ui/icons/Home";
import NeedsFilter from "./NeedsFilter";
import StoreIcon from "@material-ui/icons/Store";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DirectionsTransitIcon from "@material-ui/icons/DirectionsTransit";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import FavoriteIcon from "@material-ui/icons/Favorite";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

export interface NeedsReferralsProps {
  needs?: Need[];
  patientName: string;
}

export interface Need {
  category: string;
  title: string;
  updatedDate?: string;
  createdDate?: string;
  description: string;
  status: string;
  referrals?: Referral[];
}

const NeedsReferrals: React.FC<NeedsReferralsProps> = (
  props: NeedsReferralsProps
) => {
  // @ts-ignore
  let categories: {[key in keyof typeof Category]: Need[]} = {};
  props?.needs?.forEach(need => {
    // @ts-ignore
    if (!categories[need.category]) {
      // @ts-ignore
      categories[need.category] = [need]
    } else {
      // @ts-ignore
      categories[need.category].push(need)
    }
  })
  return (
    <PageContainer>
      <NeedContainer>
        <NeedsFilter categories={categories} name={props.patientName} />
      </NeedContainer>
      <TableContainer>
        {
          Object.keys(categories).map((item, i) => {
            return (
              <Table key={`need-category-${i}`} id={item}>
              <TableHeader>
                <tr>
                  <th colSpan={3}>
                    {getIcon(item)}
                    <span style={{ marginLeft: "3px" }}>{item}</span>
                  </th>
                </tr>
              </TableHeader>
                { // @ts-ignore
                  categories[item].map((need, j) => {
                  return (
                    <tbody key={`need-${j}`}>
                    <tr>
                      <td style={{ width: "35%" }}>
                        <NeedInfo
                          title={need.title}
                          description={need.description}
                          updatedDate={need.updatedDate}
                          createdDate={need.createdDate}
                        />
                      </td>
                      <td style={{ width: "9rem" }}>
                        <Status status={need.status} />
                      </td>
                      <td style={{ padding: ".75rem", verticalAlign: "top" }}>
                        <ReferralCard referrals={need.referrals} />
                      </td>
                    </tr>
                    </tbody>
                  )
                })
                }
                </Table>)
            })
        }
      </TableContainer>
    </PageContainer>
  );
};

export enum Category {
  education = "Education",
  food = "Food",
  housing = "Housing",
  care = "Care",
  goods = "Goods",
  health = "Health",
  legal = "Legal",
  money = "Money",
  transit = "Transit",
  work = "Work",
  emergency = "Emergency",
}

export const getIcon = (categoryName: string, fontSize?: string) => {
  const iconStyle = { fontSize: fontSize || "10pt", marginBottom: "3px" };
  switch (categoryName) {
    case Category.education:
      return <SchoolIcon style={iconStyle} />;
    case Category.food:
      return <LocalDiningIcon style={iconStyle} />;
    case Category.housing:
      return <HomeIcon style={iconStyle} />;
    case Category.care:
      return <FavoriteIcon style={iconStyle} />;
    case Category.goods:
      return <StoreIcon style={iconStyle} />;
    case Category.health:
      return <LocalHospitalIcon style={iconStyle} />;
    case Category.legal:
      return <AccountBalanceIcon style={iconStyle} />;
    case Category.money:
      return <AttachMoneyIcon style={iconStyle} />;
    case Category.transit:
      return <DirectionsTransitIcon style={iconStyle} />;
    case Category.work:
      return <LocationCityIcon style={iconStyle} />;
    case Category.emergency:
      return <NotificationsActiveIcon style={iconStyle} />;
  }
};

export default NeedsReferrals;
