import {
  CarePlan,
  Organization,
  ServiceRequest,
} from "../containers/Home/dataConverter";
import { RelatedPerson } from "./RelatedPerson";
import { CareTeam } from "./CareTeam";
import { Practitioner } from "./Practitioner";
import { Composition } from "./Composition";
import { Encounter } from "./Encounter";
import { Task } from "./Task";
import { Condition } from "./Condition";
import { Patient } from "./Patient";

export function isGoal(
  object:
    | Composition
    | CarePlan
    | Encounter
    | Task
    | Goal
    | Patient
    | CareTeam
    | Practitioner
    | RelatedPerson
    | ServiceRequest
    | Organization
    | Condition
): object is Goal {
  return (object as Goal).resource.resourceType === "Goal";
}

export interface Goal {
  resource: {
    lifecycleStatus: string;
    subject: { reference: string; type: string };
    description: { text: string };
    id: string;
    resourceType: string;
    target: { dueDate: string }[];
  };
  fullUrl: string;
}
