import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
`;

export const PersonAvatarContainer = styled.img`
  border-radius: 50% !important;
  width: 120px;
  padding-right: 0;
`;

export const TableHeader = styled.thead`
  background-color: whitesmoke;
  & th {
    font-weight: 400 !important;
  }
`;

export const PatientInfo = styled.p`
  font-weight: 200;
  font-size: 16pt;
  margin-bottom: 16px;
`;

export const ElementInfoParagraph = styled.div`
  font-weight: 200;
  font-size: 16pt;
`;

export const ElementLink = styled.a`
  cursor: pointer;
`;

export const Description = styled.div`
  max-width: 83%;
  padding-right: 15px;
  padding-left: 15px;
`;

export const NeedDate = styled.span`
  color: #6c757d !important;
`;
