import styled from "styled-components/macro";
export const PersonAvatarContainer = styled.img`
  width: 100%;
  border-radius: 50% !important;
  width: 4rem;
  padding-right: 0;
`;
export const PersonCardContainer = styled.div`
  display: flex;
`;
export const PersonNameContainer = styled.div``;

export const PersonRoleContainer = styled.div`
  color: #6c757d !important;
`;

export const PersonData = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
`;
