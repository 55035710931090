import * as React from "react";
import { NeedDatesContainer, NeedDescription, NeedTitle } from "./styled";

interface NeedInfoProps {
  title: string;
  description: string;
  updatedDate?: string;
  createdDate?: string;
}

const NeedInfo: React.FC<NeedInfoProps> = (props: NeedInfoProps) => {
  const parseDate = (date: string | undefined): string | undefined => {
    if (date) {
      const x = new Date(date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(x);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(x);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(x);
      return `${da} ${mo} ${ye}`;
    }
  };
  return (
    <div>
      <NeedTitle>{props.title}</NeedTitle>
      <NeedDescription
        dangerouslySetInnerHTML={{ __html: props.description }} />
      <NeedDatesContainer>
        <p>Need identified {parseDate(props.updatedDate)}</p>
        <p>Updated {parseDate(props.createdDate)}</p>
      </NeedDatesContainer>
    </div>
  );
};

export default NeedInfo;
